// Main imports
import { Routes, Route, Navigate } from "react-router-dom";

// Views and components
import Footer from "./components/ui/Footer/Footer";
import Home from "./views/Home/Home";
import Locations from "./views/Locations/Locations"


// Data and context
import WebplaceProvider from "./store/webplace-provider";

// Utilities
import "./App.css";

const App = () => {
  return (
    <WebplaceProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/locations" element={<Locations />} />
      </Routes>
      <Footer />
    </WebplaceProvider>
  );
};

export default App;
